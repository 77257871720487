<template>
  <svg
    class="inline animate-spin mr-2"
    width="25"
    height="25"
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M48 25C48 12.2975 37.7025 2 25 2"
      stroke="black"
      stroke-width="4"
    />
    <path
      d="M48 25C48 37.7025 37.7025 48 25 48"
      stroke="white"
      stroke-width="4"
    />
    <path d="M2 25C2 12.2975 12.2975 2 25 2" stroke="white" stroke-width="4" />
    <path
      d="M25 48C12.2975 48 2 37.7025 2 25"
      stroke="#C9000C"
      stroke-width="4"
    />
  </svg>
</template>

<script>
  export default {
    name: "Spinner",
    props: {},
    data() {
      return {};
    },
    components: {},
    computed: {},
    methods: {},
    mounted() {},
  };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
