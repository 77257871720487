<template>
  <div class="md:max-w-3/4 ml-auto mr-auto p-6 bg-white rounded-lg mb-4">
    <h1 class="font-bold text-lg text-center text-darkblue mb-3">
      {{
        $route.params.mode == "new" ? "New Expense Item" : "Update Expense Item"
      }}
    </h1>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(SubmitExpenseItem)" class="">
        <div class="ml-auto mr-auto w-full md:w-10/12 mb-4">
          <label class="block md:text-xs" for="Customer's name">
            Item Name
          </label>
          <validation-provider rules="required|custom_text" v-slot="{ errors }">
            <input
              type="text"
              v-model="expenseItem.expenseItemName"
              name="Item Name"
              class="border-2 md:text-xs md:w- w-full border-darkblue rounded-lg block"
            />
            <span class="text-red-700 md:text-xs">{{ errors[0] }}</span>
          </validation-provider>
        </div>

        <button
          type="submit"
          class="text-white block md:text-xs bg-darkblue hover:text-black hover:bg-green-400 pl-3 pt-2 pb-2 pr-3 ml-auto mr-auto rounded-full"
        >
          <Spinner v-if="loadingSpinner" />
          {{
            $route.params.mode == "new"
              ? "Add ExpenseItem"
              : "Save Expense Item"
          }}
        </button>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
  import gql from "graphql-tag";
  import { ValidationProvider, ValidationObserver } from "vee-validate";
  import Spinner from "../../components/Spinner.vue";
  import { mapMutations } from "vuex";

  export default {
    name: "ManageExpenseItem",
    props: {},
    components: { ValidationProvider, ValidationObserver, Spinner },
    apollo: {
      expenseItem: {
        query: gql`
          query expenseItem($id: ID!) {
            expenseItem(id: $id) {
              _id
              expenseItemName
            }
          }
        `,
        variables() {
          return {
            id: this.$route.params.id,
          };
        },
        skip() {
          return this.$route.params.mode != "edit";
        },
        error(error) {
          this.$emit("show-alert", {
            type: "error",
            message: error.message,
          });
        },
        watchLoading(isLoading, countModifier) {
          if (isLoading === true && countModifier === 1) {
            this.loadingSpinner = true;
            return;
          }

          this.loadingSpinner = false;
        },
      },
    },
    data() {
      return {
        expenseItem: {
          expenseItemName: "",
        },
        loadingSpinner: false,
      };
    },
    computed: {},
    methods: {
      ...mapMutations(["setBreadcrumbs"]),

      SubmitExpenseItem() {
        if (this.$route.params.mode != "edit") {
          this.CreateExpenseItem();
          return;
        }
        this.UpdateExpenseItem();
      },
      async CreateExpenseItem() {
        this.loadingSpinner = true;
        try {
          const { data, errors } = await this.$apollo.mutate({
            // Query
            mutation: gql`
              mutation($expenseItem: ExpenseItemInput!) {
                createExpenseItem(expenseItem: $expenseItem) {
                  _id
                  serialId
                }
              }
            `,
            // Parameters
            variables: {
              expenseItem: {
                expenseItemName: this.expenseItem.expenseItemName,
              },
            },
          });

          this.loadingSpinner = false;
          if (!!errors && data == null) {
            const errorResponse = errors.find((error) => {
              return error.extensions.code == "BAD_USER_INPUT";
            });
            return this.$emit("show-alert", {
              type: "error",
              message: errorResponse.message,
            });
          }
          if (!errors && !!data.createExpenseItem._id) {
            this.$emit("show-alert", {
              type: "success",
              message: `Expense Item created successfully`,
            });

            return this.$router.push("/app/expenseItems");
          }
          this.$emit("show-alert", {
            type: "error",
            message: `Error creating expense items,check your network or contact your developer`,
          });
        } catch (error) {
          this.loadingSpinner = false;

          this.$emit("show-alert", {
            type: "error",
            message: `Error creating expense items,check your network or contact your developer`,
          });
        }
      },
      async UpdateExpenseItem() {
        this.loadingSpinner = true;
        try {
          const { data, errors } = await this.$apollo.mutate({
            // Query
            mutation: gql`
              mutation($id: ID!, $expenseItem: ExpenseItemInput!) {
                updateExpenseItem(id: $id, expenseItem: $expenseItem) {
                  _id
                  serialId
                }
              }
            `,
            // Parameters
            variables: {
              id: this.$route.params.id,
              expenseItem: {
                expenseItemName: this.expenseItem.expenseItemName,
              },
            },
          });

          this.loadingSpinner = false;
          if (!!errors && data == null) {
            const errorResponse = errors.find((error) => {
              return error.extensions.code == "BAD_USER_INPUT";
            });
            return this.$emit("show-alert", {
              type: "error",
              message: errorResponse.message,
            });
          }
          if (!errors && !!data.updateExpenseItem._id) {
            this.$emit("show-alert", {
              type: "success",
              message: `Expense Item updated successfully`,
            });

            return this.$router.push("/app/expenseItems");
          }
          this.$emit("show-alert", {
            type: "error",
            message: `Error updating expense items,check your network or contact your developer`,
          });
        } catch (error) {
          this.loadingSpinner = false;

          this.$emit("show-alert", {
            type: "error",
            message: `Error updating expense items,check your network or contact your developer`,
          });
        }
      },
    },
    mounted() {
      this.setBreadcrumbs([
        { displayName: "Expense Items", pathName: "expenseItems" },
        ...(this.$route.params.mode == "edit"
          ? [
              {
                displayName: "Single Expense Item",
                pathName: "singleExpenseItem",
                params: { id: this.$route.params.id },
              },
            ]
          : []),
        {
          displayName: "Manage Expense Item",
          pathName: "manageExpenseItem",
          params: { id: this.$route.params.id, mode: this.$route.params.mode },
        },
      ]);
    },
  };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
