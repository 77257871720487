import { render, staticRenderFns } from "./ManageExpenseItem.vue?vue&type=template&id=c4ed03f8&scoped=true&"
import script from "./ManageExpenseItem.vue?vue&type=script&lang=js&"
export * from "./ManageExpenseItem.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c4ed03f8",
  null
  
)

export default component.exports